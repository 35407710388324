 #title{
  padding: 12px;
   color: #0468d7 ;
   text-transform: uppercase;
   padding-top: 12px;
   margin: auto;
   margin-bottom: 22px;
   margin-top: 22px;
   font-family: Arial, Helvetica, sans-serif;
   font-weight: bold;
   
 
   
}
.totalBd{
  color: #0468d7 ;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  
  
}
.description_thematique{
  font-family: Arial, Helvetica, sans-serif;
  border-bottom: 1px solid #9f9fa3 ;
  color: #979898 ;
  padding-bottom: 10px;
  margin-bottom: 20px;
  margin-top: 20px;
  --text-align:justify; 
  word-spacing: normal;
  height: 88px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-size: 18px;
 
}
.nom_thematique{
  color: #0468d7 ;
 
  
  height: 70px;

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.uneThematique .image_thematique {
  height: 147px;
  width: 149px;
  margin: auto;
  --margin-top: -52px;
 

}
.btn_plus:hover {
  --color: #0468d7 ;
 
}

.voirplus:hover {
  background: #ffae85;
}

.voirplus{
  background-color:#F86923 ;
  color: white;
  padding: 12px;
  border-radius: 50px  50px 50px  50px  ;
  font-weight: bold;
  margin: auto;
  --margin-bottom: 20px;
  border: none;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  
  
}
#btntout_them,#btntop4_them{
  background-color: #fcfafa;
  padding-bottom: 34px;
 
}
.btn_plus{
  color: white;
  padding: 20;
  font-weight: bold;
}

.btns_plus_moins:hover{
text-decoration: none;
}
.uneThematique{
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 40px;
}
#icone{
  border-left: 7px solid #0468d7  ;
  margin-right: 15px;
  }
#containListe,#voirtout{
  width: 100%;
  margin: auto;
  background-color: #fcfafa;
  --margin-left: 12px;
  --box-shadow:  0 .5em 1.5em rgba(87, 89, 92, 0.1),  0 .125em .5em rgba(115, 118, 121, 0.1);
 
}
.uneThematique{
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  background-color: white;
  box-shadow:  0 .5em 1.5em rgba(171, 212, 250, 0.1),  0 .125em .5em rgba(219, 235, 250, 0.1);
  --margin-left:17px;
  
  --margin-right:21px;
 
  border-radius: 20px;
  border: none;
  --padding: 20px;
  --padding-bottom: 10px;
 
  
}
.uneThematique:hover >.nom_thematique{ 
  color: #fcfaf9;
  font-weight: bold;
}
.uneThematique:hover >.totalBd{ 
  color: #9b3604;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  letter-spacing: 1px;
}
.uneThematique:hover >.description_thematique{ 
  color: rgb(195, 195, 197);
  border-bottom: 1px solid rgb(228, 228, 228);

}
.uneThematique:hover {
  width: 50%;
  --font-size: larger;
 width: 170%;
  --padding: 20px;
  

  background-color: #0468d7;
}
.nbre_bd{
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

/*----------------------Partie Page Liste BD ------------------------------------*/
.ul_petit_header{
  margin-left: 10px;
  border-bottom: 1px solid #ddd;
  background-color: #eff4fc;
  border-radius: 0 3px 0 0;
  --margin: 40px 0 20px;
  padding-top: 20px ;
  padding-left: 90px ;
  padding-right: 10px ;
  font-family: 'Times New Roman', Times, serif;
 
}

.grand-Div .liens_retour{
  float: left;
   margin-bottom: 15px;
   margin-top: 9px;
  color: gray;
  font-size: 19px;
  font-weight: bold;
  text-decoration: none;
  font-family: 'Times New Roman', Times, serif;
}
.grand-Div .liens_retour:hover{
  text-decoration: none;
  color: #6a70b9;
  font-size: 20px;
}

.petit_header{
  margin:  16px;
  font-family: 'Times New Roman', Times, serif;
  z-index: 0;
}
.btn_recherche{
  color: white;
  font-weight: bold;
  --border: 2px solid #3f53e7 ;
  background-color: #6a70b9 ;
  margin: auto;
  border-radius: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 50px;
  padding-right: 50px;
  font-size: 20px;
  font-family: 'Times New Roman', Times, serif;
}

.tri{
  float: right;
  font-family: 'Times New Roman', Times, serif;
}
.link-petit-header{
  font-size: larger;
  color: black;
  font-family: 'Times New Roman', Times, serif;
 
}
.petit_header  a {
  text-decoration: none;
  color:black;
  font-family: 'Times New Roman', Times, serif;
  
}
.link-petit-header:hover {
  color:#6a70b9 ;
  text-decoration: none;
  
}
.link-petit-header {
  color:#6a70b9 ;
  
}

.contain_recherche{
  margin-top: 30px;
  font-family: 'Times New Roman', Times, serif;
}
.description_thematiq {
  float: left;
  text-align: left;
  margin-top: 18px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 20px;
  
}


.description_bd_thematiq{
  float: left;
  text-align: left;
  margin-top: 12px;
  font-family: 'Times New Roman', Times, serif;
  font-size: 19px;
}
.div_bd .lien{
  text-decoration: none;
  font-size: 17px;
  color: #3f53e7;
  float: left;
  margin-top: 12px;

}
.div_bd{
  --margin-left: 5.5%;
  --margin-bottom: 3%;
border: none ;
  border-radius: 18px;
  background-color: white;
  border-bottom: 1px solid lightgray;
 
}
.div_bd:hover{
  padding: -30px;
  background-color: #eff4fc;
  box-shadow: 12px 12px #fff;
  
}
.icone_bd_locked_or{
  color: #09116d;
  font-size: 30px;
  align-items: center;
  float: center;
  margin: auto;
  border: 2px solid #09116d;
  padding-top: 12px;
  padding-bottom: 12px;
  
}

@media (max-width:991px) {
  .div_bd{
    margin: 1%;
    margin-right: 4%;
    margin-bottom: 4%;
   
}
}
.grand-Div{
 
}

.div_bd .lien:hover{
  
  font-size: 18px;
  color: #03106b;
}
.le_nom_de_la_bd{
  color: #0f187c;
  float: left;
}
.bd_thematiq{
  font-size: 18px;
  color: gray;
}

.dates{
  float: right;
    position: relative;
    right: 12;
    font-size: 15px;
    color: rgb(109, 108, 108);
    font-family: 'Times New Roman', Times, serif;
}
.thematique{
  color:#1421ac ;
  font-family: 'Times New Roman', Times, serif;
}
.nbre_bd_thematiq{
  color:red ;
  font-weight: bolder;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.contain_tout_liste{
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.nom_bd{
font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
color: #545556;
margin-left: 12px;
}
.lock{
font-size: 30px;
color: green;
}
.unlock{
  font-size: 30px;
  color: rgb(246, 87, 48);
  }
.uneBD{
  border: none;
  background-color: white;
  text-align: left;
}
.groupe_bd{
margin-bottom: 18px;
}
.desc_bd{
color: #979898;
padding: 5px;
margin-left: 5px;
  
    text-align:left; 
    word-spacing: normal;
    height: 32px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 17px;
   
}
/*-----------------------------------------addThematique-----------------------------*/
.texte_champs_vides{
  color: rgb(193, 12, 12);
  font-size: smaller;
  margin-top: -12px;
}
.description_de_la_bd{
  font-family: Arial, Helvetica, sans-serif;
  color: #4e4f4f ;
  padding-bottom: 10px;
  margin-bottom: 20px;
  margin-top: 20px;
  --text-align:justify; 
  word-spacing: normal;
  height: 78px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-size: 17px;
}
.modal_add_them{
  background-color: #9b3604;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.btn-add-thematique{
  font-size: 25px;
  border:2px solid #F86923;
  border-radius: 7px;
  float: right;
  color: #F86923;
  font-family: bolder;
  font-family: Arial, Helvetica, sans-serif;
 margin-bottom: 20px;
 padding-left: 7px;
 padding-right: 7px;
 --padding-bottom: 2px;
 padding-top: 3px;
}
.btn-add-thematique:hover{
  color: #fff;
  background-color: #F86923;
  --font-weight: bold;
}
.btn-add-thematique:hover {
  opacity: 1;
  padding: 7px;
  font-size: 19px;
}

.btn-add-thematique-hover{
  font-size: 16px;
  background-color: rgb(232, 235, 233);;
  color: rgb(141, 135, 135);
  font-family: bolder;
  text-transform: none;
  margin-left: 50%;
  padding: 9px;
  border-radius: 12px;
  opacity: 1;

}
.ajout_theme{
  display: none;
}
.btn-add-thematique:hover>.ajout_theme,.btn-add-thematique{
  display: inline;
  --color: white;
  --font-size: 19px;
}
.pencil{
  color:white;
  float: right;
  --border:1px solid rgb(172, 126, 126);
  padding: 3px;
  font-size: 22px;
  background-color:rgb(103, 152, 226);
  border-radius: 6px;
  padding-left: 8px;
  padding-right: 8px;

}

.bi-theme{
    font-size: 1992%;
    color: blue;
}
.pencil:hover{
  background-color: rgb(39, 30, 128);
  color: white;
}
.supprimer {
  color:white;
  background-color: rgb(252, 58, 58);
  margin-right: 3px;
}

.btns{
  float: right;
  border: none;
  
 
}

.groupe_thematique{
  margin-top: -25px;

}
.nouveau_pays{
  background-color: rgb(230, 233, 233);
  padding: 12px;
  border-radius: 12px;
  --margin-top: -100px;
  
}
.zone{
  font-size: 18px;
  font-family: 'Times New Roman', Times, serif;
}
.fermer{
  float: right;
  color: red;
  font-size: 25px;
  border: none;
  margin-bottom: 12px;
}
.ajoutPays:hover{
  color:rgb(57, 178, 182)
}
.ajoutPays{
  float: right;
  color: rgb(162, 170, 168);
  border: none;
  background-color: white;
}
.btn_quitter_them{
  background-color: #4e4f4f;
}
.champs_etoile{
  font-size: smaller;
}
.etoile_rouge{
  color: red;
  font-weight: bold;
  font-size: larger;
}
/*----------------------------CSS DEtails BD --------------------------*/
.petit_header_bd {
  font-size: 16px;
  --background-color: #f9c2ab;
  --border-bottom:4px solid #044b9e ;
  
}
.ul_right{
  float: right;
  margin-left:20px ;
}
.descrption_titre{
  color: white;
}
.descrption, .detail{
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 27px;
 
}
.div_top_detail{
  font-size: 20px;
  margin-top: 10px;
  font-weight: bold;
  --background-color: #e8eff6;
}
.sous_div_top{
  --background-color: white;
  margin: .5%;
  border-radius: 12px;
}
.valeur{
  font-weight: normal;
  font-size: 19px;
}
.descrption {
  font-size: 19px;
  text-align: left;
  padding: 4;
}
.entete{
  text-align: center;
  font-weight: bold;
  font-size: 25px;
  background-color: #0468d7;
  padding: 20px;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
}
.icone_{
  color:#044b9e;
  font-weight: bold;
  margin-right: 5px;
}
.la_description{
  font-size: 21px;
  text-align: justify;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.lien_bd{
color: #2e2a28;
font-weight: bold;

}
.lien_thematique{
  color: #4f5166;
}
.nom_mot{

}
.mots{
  background-color: rgb(206, 205, 205);
  padding: 9px;
  color: #454342;
}