.stat1, .stat2,.stat3,.stat4{
    border-radius: 12px;
    margin: 6px;
    margin-bottom: 4%;
    background-color: white;
   
}

.stat1{
    border-left: 9px solid rgb(11, 109, 48);
  
}

.stat2{
    border-left: 9px solid #F86923;
   
   
}

.stat3{
    border-left: 9px solid rgb(202, 85, 187);
   
}
.stat4{
    border-left: 9px solid rgb(9, 104, 109);;
   
}
.nom_val_stat{
    text-align: left;
    text-transform: uppercase;
    font-family: 'Times New Roman', Times, serif;
    font-weight: bolder;
    font-size: 17px;
   
    
}

.valeur_stat{
    font-size: 40px;
    color: rgb(95, 91, 91);
    
}
.nom_stat1{
    color: rgb(11, 109, 48);
    
}

.nom_stat2{
    color: #F86923;
}

.nom_stat3{
    color: rgb(202, 85, 187);
    font-weight: bolder;
}

.nom_stat4{
    color: rgb(9, 104, 109);
    font-weight: bolder;
}

.icone_stat{
    font-size: 30px;
    color: rgb(131, 127, 127);
    
}
.liste_statistiques{
    background-color: #0468d7;
    
}
#icone_stat{
    border-left: 7px solid white ;
    margin-right: 15px;
    }
    #title_stat{
        padding: 38px;
         color: white;
         text-transform: uppercase;
         padding-top: 38px;
         margin: auto;
         --margin-bottom: 22px;
         margin-top: 22px;
         font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
         font-weight: bold;
         
       
         
      }