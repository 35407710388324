.card_entite{
    background-color: rgb(251, 253, 247);
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 23px;
   
    z-index: 999;
    position: absolute;
   

    top: -500%;
    left: 50%;
    right: auto;
    bottom: auto;
    --margin-right: -50%;
    transform: translate(-50%, -50%);
   
 
   
}
.fermer-plus{
    background-color: rgb(252, 253, 249);
    font-size: 35px;
    border-radius: 12px;
    margin-top: -720px;
    z-index: 1000;
    
}
.addEntite{
    
    margin:auto;
   
    
    
}