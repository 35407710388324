.items{

    border:none;
    --background-color: #0468d7 ;
    color: rgb(205, 206, 252);
    font-size: 22px;
    float: left;
    text-align: left;
    margin-right: 12px;
    margin-left: 12px;
    --font-weight:bold;
    margin-bottom:20px ;
}

.item{
    border:none;
    --background-color: #0468d7 ;
    color: rgb(255, 255, 255);
    font-size: 21px;
    float: left;
    text-align: left;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    --font-weight:bold;
    margin-bottom:20px ;
}
.item_menu_user_:hover{
background-color: #044b9e;
padding-top: 12px;
--margin: 12px;

}
.nbre_notif{
    color: rgb(253, 14, 14);
    font-weight: bolder;
    font-size: 19px;
    margin-left: -63%;
    --margin-bottom: 19px;
    margin-top: -7px;
    --background-color: white;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}


.span_menu{
    font-size: 32px;
    float: center;
    padding: auto;
    margin: auto;
    margin-top: 12px;
    font-weight: bold;
    color: aliceblue;
    --border-bottom: 1px solid white;
}
.profil_user{
    background-color: rgb(215, 223, 226,.1);
}

.aside_menu{
    background-color: #0468d7 ;
    min-height: 560px;
    color: white;
    text-align: left;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
   
    left:0;
    z-index: 998;
    position: fixed;
    top: 2px;
    bottom: 0;
    left: 0;
   
    display: block;
    padding: 20px;
    overflow-x: hidden;
    overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */   
    border-right: 1px solid #eee;
}
.menu_{
    font-size: 27px;
    margin-top:-5px;
    margin-left: 23px;
}
.msg_demande{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    padding-bottom: 10px;
    --margin-bottom: 20px;
    --margin-top: 20px;
    text-align:left; 
    word-spacing: normal;
    height: 23px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 17px;
   
}
.msg_demande_tableau{
    
    padding: 10px;
    --margin-bottom: 20px;
    --margin-top: 20px;
    text-align:left; 
    word-spacing: normal;
    height: 92px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    font-size: 17px;
   
   
}
.etat{
font-size: 30px;
font-weight: bolder;
color: rgb(9, 172, 9);
}

.etat_notreply{
    font-size: 30px;
    font-weight: bolder;
    color: red;
    }

.btn_notification{
    border: none;
    background-color: white;
    border-bottom: 1px solid gray;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.msg_td_dmd{
    width: 29%;
}
.table_dmd{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.btn_notification:hover{
   
    background-color: rgb(207, 204, 204);
    padding: 24px;
    font-size: 17px;
    
}
th{
    color: #044b9e;
    font-size: large;
}

/*========================================table===================*/
.demande_liste{
    --overflow-y: scroll;
    --height: 560px;
}
 /*========================================profil user ===================*/
 body {
    --background: rgb(99, 39, 120)
}
.black{
    color: black;
    font-weight: bold;
}

.form-control:focus {
    box-shadow: none;
    border-color: #BA68C8
}

.profile-button {
    background: rgb(99, 39, 120);
    box-shadow: none;
    border: none
}

.profile-button:hover {
   --background: #682773
}

.profile-button:focus {
    --background: #682773;
    box-shadow: none
}





.labels {
    font-size: 16px;
    float: left;
    color: rgb(77, 76, 76);
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}


.text_title{
    margin-top: 20px;
    margin-bottom: -10px;
    color: #15355a;
   
}

.div_top{
    height: 173px;
    background-color: #0468d7;
    width: 105%;
    border-radius: 12px;
}
.div_bottom{
    border-radius: 12px;
    background-color: aliceblue;
    padding-top: 10px;
    margin-top: -50px;
   
}
  
/*menu deroulant*/ 
 
.menu_deroull .sousmenu  {
    display: none;
    float: none;  
    text-align: left;
    background-color: white;
    z-index: 1000;
    position: absolute;
    overflow-x: visible;

    
  }
  .menu_deroull .sousmenu  li {
    float: none;
    width: 100%;
    text-align: left;
    z-index: 99999;
    overflow-x: visible;
    
    
  }
  .menu_deroull{
    float: left;
    margin-left: -16px;
    overflow-x: visible;
    margin-top: -7px;
  }

  .navbar >ul li:hover   .sousmenu{
    display: block;
    box-shadow: 0px 5px 6px #CCC;
    border-top: 3px solid #3d5cc0  ;
    background-color: white;
    z-index: 99999;
    overflow-x: visible;
  }
 
  .sousmenu a{
    padding: 10px;
    border-bottom: none;
    text-decoration: none;
    overflow-x: visible;
  }
  .menu_deroull .sousmenu a:hover{
    background-color: #0c1f8d;
    border-bottom: none;
    text-decoration: none;
    color: white;
    overflow-x: visible;
  }
  .bi_togle{
    margin-left: 10px;
    font-size: large;
  }
