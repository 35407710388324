
  body{
    width: 100%;
  }
  a {
    text-decoration: none;
    color: #345299;
  }
  
  a:hover {
    color: #345299;
    text-decoration: none;
  }
  
  /*--------------------------------------------------------------
  # Top Bar
  --------------------------------------------------------------*/
  #topbar {
    background: #0468d7  ;
    color: rgb(247, 246, 243);
    font-size:18px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    line-height: 1.25;
    --font-weight: bold;
    height: 33px;
    padding: 0;
    width: 100%;
    
  }
  @media (max-width:500px) {
    #topbar{
      font-size:12px;
    }
  }

  @media (max-width:316px) {
    #topbar{
      font-size:10px;
      
    }
    .header .logo img {
      max-height: 80px;
      width: 80px;
    }
  }

  
  #topbar .contact-info a {
    line-height: 0;
    color: #F86923;
    transition: 0.3s;
  }
  #topbar .contact-info i:hover {
    color: #F86923;
  }
  #topbar .contact-info i {
    color: #fff;
    font-weight: bold;
    line-height: 0;
    margin-right: 5px;
  }
  #topbar .contact-info .phone-icon {
    margin-left: 15px;
  }
 
  #topbar .social-links a {
    color: #fff;
    padding: 4px 12px;
    display: inline-block;
    line-height: 1px;
    transition: 0.3s;
  }
  #topbar .social-links a:hover {
    color: #F86923;
  }
  
  /*--------------------------------------------------------------
  # Header
  --------------------------------------------------------------*/
  .header {
    background: #fff;
    transition: all 0.5s;
    height: 110px;
    font-size: 26px;
    z-index: 999;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    box-shadow: 0px 3px 2px #d6e7f7  ;
    
    
  }
  #headerscrolled {
    box-shadow: 0px 3px 2px #d6e7f7  ;
    position: fixed;
    top:0;
    z-index: 999;
    width: 100%;
    background: #fff;
    transition: all 0.5s;
    height: 90px;
    background-color: #fff ;
    
  }
  
  .header .logo a {
    color: #0468d7 ;
  }
  

  .header .logo img {
    max-height: 70.9px;
  }
  
  .scrolled-offset {
    margin-top: 70px;
  }
  
  /*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
  /**
  * Desktop Navigation 
  */
 
  .navbar {
    padding: 0;
  }
  .navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }
  .navbar li {
    position: relative;
  }
  .navbar a, .navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    --padding: 10px 0 10px 30px;
    font-size: 19px;
    color: #6a70b9 ;
    white-space: nowrap;
    transition: 0.3s;
  }
  .navbar a i, .navbar a:focus i {
    font-size: 22px;
    line-height: 0;
    margin-left: auto;
  }
 
  .navbar  a{
    font-size: 22px;
}

 
  .navbar a:hover,  .navbar .active:focus, .navbar li:hover > a {
    color: #4a2ebb;
    font-size: 24px;
   
   
  }
  .actived{
    color: chartreuse;
}
  .navbar .seconnecter, .navbar .seconnecter:focus {
    color: #fff;
    padding: 8px 25px;
    margin-left: 30px;
    border-radius: 4px;
    background-color: #F86923;
    transition: 0.3s;
    font-size: 18px;
    font-weight: bold;
    text-decoration: none;
   
  }
  .navbar .seconnecter:hover, .navbar .seconnecter:focus:hover {
    background: #ffae85;
    font-size: 18.6px;
    color: #0468d7 ;
   
  }
  
  
  /**
  * Mobile Navigation 
  */
  .mobile-nav-toggle {
    color: #4275f7;
    font-size: 28px;
    cursor: pointer;
    font-weight: bold;
    display: none;
    line-height: 0;
    transition: 0.5s;
  }

  @media (max-width: 1200px) {
    .mobile-nav-toggle {
      display: block;
    }
    .navbar ul {
      display: none;
    }
  }

  .navbar-mobile {
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(5, 5, 5, 0.9);
    transition: 0.3s;
    z-index: 999;
  }
  .menu{
    position: fixed;
    overflow: hidden;
    top: 65px;
    right: 15px;
    --left: 0;
    bottom: 75px;
    width: 280px;
    height: 180px;
    padding-top: -10px;
    --float: right;
    transition: 0.3s;
    --margin-left: 123px;
    padding: 0;
    z-index: 999;
   
    margin-right: 10px;
    
  }
  .bi-menu{
    margin-right: 10px;
    font-size: 128%;
    color: #3d5cc0;
    font-weight: bold;
  }
 
  
  
  .menu ul {
    display: block;
    position: relative;
    top: 7%;
    right: 3px;
    bottom: 0;
    left: 0;
    padding: 10px 0;
    background-color: #fff;
    overflow-y: auto;
    --border-radius: 12px;
    border-top :3px solid #3d5cc0;
    border-bottom :1px solid #3d5cc0;
    border-left :0.6px solid #CCC;
    border-right :1px solid #CCC;
    box-shadow: 0px 5px 6px #CCC;
  
  }
  .menu a, .menu a:focus {
    --padding: 10px 20px;
    font-size: 125%;
    color: #3d5cc0;
    font-weight: bold;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    
  }
  
  .menu a:hover, .menu .active, .menu li:hover > a {
    color: #fff;
    background-color: #F86923;
  }
  .menu a:hover>.bi-menu{
    
    color: #fff;
 
  }




  .navbar-mobile ul {
    display: block;
    position: relative;
    top: 55px;
    right: 15px;
    bottom: 75px;
    left: 15px;
    padding: 10px 0;
    background-color: #fff;
    overflow-y: auto;
    transition: 0.3s;
    
    
    
  }
  .navbar-mobile a, .navbar-mobile a:focus {
    padding: 10px 20px;
    font-size: 150%;
    color: #060c1f;
    
  }
  .navbar-mobile a:hover, .navbar-mobile .active, .navbar-mobile li:hover > a {
    color: #3d5cc0;
    
  }

  .navbar-mobile .seconnecter, .navbar-mobile .seconnecter:focus {
    border: 1px solid  #3d5cc0;
    text-decoration: none;
    width: 200%;
    border: 2px solid #3d5cc0;
    color: #3d5cc0;
    padding-left: 80px;
    padding-right: 80px;
    padding-top: 7px;
    padding-bottom: 7px;
    border-radius: 10px;
    
    
    

  }
  .profil a:hover{
    text-decoration: none;
    color: #F86923;
  }
  

  .profil .person:hover{
    background-color: #F86923;
  }
  .profil{
    font-size: 19px;
    color: white;
    --border-left: 1px solid rgb(201, 201, 198);;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    
  }
  .fleche:hover{
    
    --background-color: rgb(177, 177, 185);
  }
  .fleche{
    
    background-color: #989695;
  }

  .prenom,.nom_personne{
    color: rgb(115, 114, 114);
    margin-left: 5px;
    text-decoration: none;
  }
  .person{
    --border:1px solid rgb(107, 101, 101);
    color:#fff;
    border-radius: 50%;
    padding: 6px;
    background-color: rgb(138, 138, 138);
    padding: 7px;
    font-weight: bold;
    margin: auto;
  }
  .nom_personne{
    text-align: right;
    
  }
  
 /*menu deroulant*/ 
 
  .menu_deroul .sousmenu  {
    display: none;
    float: none;  
    text-align: left;
    background-color: white;
    z-index: 1000;
    position: absolute;

    
  }
  .menu_deroul .sousmenu  li {
    float: none;
    width: 100%;
    text-align: left;
    
    
  }

  .navbar >ul li:hover   .sousmenu{
    display: block;
    box-shadow: 0px 5px 6px #CCC;
    border-top: 3px solid #3d5cc0  ;
    background-color: white;
    
  }
 
  .sousmenu a{
    padding: 10px;
    border-bottom: none;
    text-decoration: none;
  }
  .menu_deroul .sousmenu a:hover{
    background-color: #F86923;
    border-bottom: none;
    text-decoration: none;
    color: white;
  }
  

  
  

  
  
  