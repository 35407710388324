#under-navbar{
   
    width: 100%;
    
    
}
#under-navbar-left{}
    
#under-navbar-left h1{
    margin: 0;
    font-weight: 700;
    color: #0468d7 ;
   
}

#under-navbar-left h2{
    margin: 0;
    font-weight: 400;
    line-height: 1.25;
    color: #929293 ;
    font-size: 23px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-style: normal;
   border-bottom-left-radius: 100px ;
    
    
}
#under-navbar-left button{
    margin-top: 30px;
  line-height: 0;
  padding: 15px 40px;
  padding-top: 22px;
  padding-bottom: 22px;
  border-radius: 4px;
  transition: 0.5s;
  color: #fff;
  background:  #F86923;
 

box-shadow: 0px 5px 30px #b3b6bb;

  width: 250px;
}

#under-navbar  a{
    text-decoration: none;
}
#under-navbar-left button span{
    font-size: 18px;
    font-weight: bolder;
}

#under-navbar-left button i{
  
}
.btn_recherche{
    background-color: #F86923;}

    .btn_recherche:hover {
        background: #ffae85;
        font-size: 18.6px;
        color: #0468d7 ;
       
      }

      #under-navbar-left button:hover {
        background: #ffae85;
        font-size: 18.6px;
        color: #0468d7 ;
       
      }

      