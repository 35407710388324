.btn-indicateur{
    margin: 12px;
} 
.btn_change-graphique{
    height: 90px;
    margin-top: 50px;
    cursor: pointer;

}
.btn_change-graphique:hover{
   background-color: rgb(145, 152, 150);
   padding: 7px;

}