body{
  width: 100%;
  z-index: 200;
}

.b-example-divider {
  height: 1rem;
  background-color: rgba(129, 193, 230, 0.1);
 width: 100%;
margin: auto;
  border-width: 1px 0;
  box-shadow: inset 0 .5em 1.5em rgba(107, 179, 226, 0.1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}

#home{
  overflow-x:hidden;
  max-width: 100%;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
