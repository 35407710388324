
.btn_demande{
    color: white;
    font-weight: bold;
    background-color: #F86923;
    --border: 2px solid  white;
    
    margin: auto;
    border-radius: 8px;
    margin: auto;
    margin-bottom: 209px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 50px;
    padding-right: 50px;
}
.contain_tout{
    background-color: #0468d7 ;
}
.p_demande{
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 21px;
    color: rgb(207, 215, 215);
}
.div_demande{
    margin-bottom: 29px;
    margin-top: 20px;
}
.btn_demande:hover{
    color: white;
    background: #f09060;
    width: 170px;
   
    color: white;
}

.divIndicateur{
    background-color: rgb(234, 236, 238);
    margin-top: 2%;
    border-radius: 7px;
    margin-bottom: 3%;
}
.unIndicateur ,.libelle{
    --background-color: #6a70b9;
    float: left;
    text-align: left;
    margin: auto;
    
}
.btn_envoi_{
    background-color: #4e5386;
    padding: 10px ;
    color: white;
    font-size: 22px;
    border-radius: 6px;

  
}
.avous{
    color: aliceblue;
}
.div_envoi_{
    float: left;
    text-align: left;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

}
.div_demande_{
    margin-bottom: 9px;
    margin-top: 10px;
    float: right;
}
.plus{
   font-size: 20px; 
   font-weight: bold;
}
.etape1{
    color: #0468d7;
    font-weight: bold;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.remplir{
    color: rgb(105, 108, 108);
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-style: italic;
    font-size: 20px;
    color: rgb(235, 158, 135);
    }
#theme,#bd,#entite,#message{
    color: rgb(105, 108, 108);
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 17px;
}

.etape2ettext{
    text-align: left !important;
    display: block;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.etape2{
    color: #0468d7;
    font-size: 45px;
    margin-bottom: 22px;
}
.text_demande{
    font-size: 25px;
    color: rgb(90, 89, 89);
}
.text_attention{
    font-size: 22px;
    text-align: justify;
     color: gray;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif
}
.h3_indicateur{
color: #4e5386;
font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
font-weight: bold;
font-style: italic;
margin-top: 33px;
}
.check{
margin-right: 5px;
}

/*=====================details demande==================================*/

.detail_msg{
text-align: left;

}
.text_msg{
    color: #4e5386;
    font-weight: bold;
    margin-bottom: 12px;
    float: left;

}

.detail_bd
{

font-family: 'Courier New', Courier, monospace;
font-size: 20px;
font-weight: bold;
color: red;

}
.detail_date{
    color: rgb(96, 95, 95);
}
.details{
    font-family:Verdana, Geneva, Tahoma, sans-serif;
}

.text_fichier{

}
.detail_fichier{

}
.text_bd{
    color: rgb(66, 68, 68);
    margin-right: 10px;
}
.text_etat,.text_fichier{
color: rgb(105, 102, 102);
font-weight: bold;
margin-right: 12px;
}
.text_etat{
    margin-top: 12px;
}
.detail_etat{
   margin-bottom: 23px; 
}
.detail_etat{
  
}
.text_entite{
    color: rgb(65, 64, 64);
    font-size: 20px;
    margin-right: 10px;
    font-weight: bold;
}
.detail_entite{
    color: #0468d7; 
    margin-top: 20px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 19px;
}
.text_nom{
    --margin-left: 22px;
    font-weight: bold;
    font-size: large;
    color: gray;
    --margin-right: 20px;
    width: 20%;
}
.valeur_nom{
    margin-left: 22px;
    --font-weight: bold;
    font-size: 17px;
    --margin-right: 23px;
    font-style: italic;
    color: grey;
}