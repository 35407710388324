.title_news{
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: #0468d7;
    font-style: bold;
    padding: auto;
    padding-left: 12px;
    margin-top: 30px;
    font-size: 35px;
    font-weight: bold;
    margin-bottom: 30px;
    border-bottom: 1px solid #0468d7;  
    
}
.image{
    --box-shadow:  0 .125em .5em rgba(92, 93, 94),  0 .125em .5em rgba(73, 74, 75);
    
}

#allbd{
    --display: none;
}
.allbds{
display: none;
}
.une_bd_recente .le_nom_de_la_bd, .div1 .le_nom_de_la_bd{
    font-weight: bold;
    font-weight: bold;
    font-size: 20px;
    color: #0468d7;
}
.une_bd_recente  .lien{
    text-decoration: none;
   font-size: 17px;
  
  
}
.div1 .lienn{
    text-decoration: none;
   font-size: 17px;
}

.date{
    text-align: center;
    position: absolute;
    margin-right: 20px;
    right: 0;
    font-size: 16px;
    color: grey;
    --margin: 12px;
 
}
.news{
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.description_de_la_bd{
    margin-top: 20px;
    font-size: 18px;
    float: left;

}

.div_sur_limage{
    
    box-shadow:  0 .125em .5em rgba(92, 93, 94),  0 .125em .5em rgba(73, 74, 75);

    background-color: #fff;
    position: absolute;
    --bottom: calc(0% + 3%);
    width: 75%;
    text-align: left;
    padding-top: 20px;
    margin-top: 6%;
    --margin-bottom: 20%;
    padding-right: 50px;
    padding-left: 50px;
    z-index: 99;
    border-radius: 10px;
    border-bottom: 3px solid #0468d7;
}
@media (max-width:991px) {
    .div_sur_limage{
        bottom: -20%;
        width: 100%;
    }
}
.bds_recentes{
   text-align: left; 
}
.une_bd_recente{
    
}