.entite_nom_desc{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    margin-bottom: 12px;
    border-radius: 6px;
    background-color: white;
    font-weight: bold;
    margin: 24px;
    
  
    
}
.entites{
    background-color: #fafbfc;
    
}
.nom_entite{

color: white;
font-size: 22px;
border-radius: 3px;
margin-top:-23px;


--border-top-right-radius: 7px;

}
.add-entite{
    --padding: 12px;
    padding-left: 12px;
    padding-right: 12px;
    float: center;
    
    
}
.Entite{
    margin-top: 17%;
    margin-bottom: -18%;
    
    
}

.description{
float: left;

color: black;
font-size: 14px;
font-family: sans-serif;
padding-top: 19px;
padding-bottom: 13px;
border-bottom: 1px solid lightgrey;
text-align: left;
}
.indicateurs{
    padding: 18px;
    
    font-size: 19px;
    color: rgb(136, 131, 131);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: bold;
   
}
.btn_plus_indicateurs{
    --margin-right: 16px;
}
.collapse{
    float: left;
    text-align: left;
}
.ajout_ind{
    font-weight: bold;
    border: none;
    padding: 10px;
    border-radius: 5px;
    font-size: 17px;
    margin-top: 12px;
}
.pie-chart{
    font-size: 90px;
    color: rgb(206, 204, 204);
}

.no-data{
    margin: auto;
    text-align: center;
}
.with-data{
    margin: auto;
    text-align: center;
}

.texte_no-indicateur{
    font-size: 20px;
}
.indicateur_{
    font-family:  Trebuchet MS;
    font-size: 18px;
    text-transform: capitalize;
}
.btn_ajout_indicateur{
    float: right;
    text-align: right;
}

.label_indicateurs{
text-align: left;
float: left;
font-size: 21px;
}

#my-file,#my-file-datas{
 background-color: #bbbbfa;   
}
.nom_entite{
    background-color:#0468d7 ;
}
.btn_envoi_{
    background-color: #acc0d7;
}
.btn_envoi_:hover{
    background-color: #05274d;
}
.une{
border: none;
height: 150px;
border-radius: 12px;
background-color: #afc3da;

}
.une:hover{
    height: 160px;
   
    background-color: #b1becd;
}
.nom_{
color: white;
font-weight: bold;
font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}