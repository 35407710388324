/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: #F86923;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}
.back-to-top i {
  font-size: 24px;
  color: #fff;
  line-height: 0;
}
.back-to-top:hover {
  background: #f1a67a;
  color: #fff;
}
.back-to-top.active {
  visibility: visible;
  opacity: 1;
}


.lien{
    text-decoration: none;
}
#footer {
    background: #030446;
    padding: 0 0 30px 0;
    color: #fff;
    font-size: 14px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  }
  #footer .footer-top {
    background: #083e7d ;
    border-bottom: 1px solid #222222;
    padding: 60px 0 30px 0;
 
  }
  #footer .footer-top .footer-info {
    margin-bottom: 30px;
  }
  #footer .footer-top .footer-info h3 {
    font-size: 28px;
    margin: 0 0 20px 0;
    padding: 2px 0 2px 0;
    line-height: 1;
    font-weight: 700;
    text-transform: uppercase;
  }
  #footer .footer-top .footer-info h3 span {
    color: #F86923;
  }
  #footer .footer-top .footer-info p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    font-family: "Raleway", sans-serif;
    color: #fff;
  }
  #footer .footer-top .social-links a {
    font-size: 18px;
    display: inline-block;
    background: #F86923;
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 4px;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
  }
  #footer .footer-top .social-links a:hover {
    background: #f1a67a;
    --color: #151515;
    text-decoration: none;
  }
  #footer .footer-top h4 {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    position: relative;
    padding-bottom: 12px;
  }
  #footer .footer-top .footer-links {
    margin-bottom: 30px;
    text-decoration: none;
  }
  #footer .footer-top .footer-links ul {
    list-style: none;
    text-decoration: none;
    padding: 0;
    margin: 0;
  }
  #footer .footer-top .footer-links ul i {
    padding-right: 2px;
    text-decoration: none;
    color: #F86923;
    font-size: 18px;
    line-height: 1;
  }
  #footer .footer-top .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
    text-decoration: none;
    margin-left: 85px;
  }
  #footer .footer-top .footer-links ul li:first-child {
    padding-top: 0;
  }
  #footer .footer-top .footer-links ul a {
    color: #fff;
    transition: 0.3s;
    display: inline-block;
    text-decoration: none;
    line-height: 1;
  }
  #footer .footer-top .footer-links ul a:hover {
    color: #F86923;
  }
  
  #footer .copyright {
    text-align: center;
    padding-top: 30px;
  }
  #footer .credits {
    padding-top: 10px;
    text-align: center;
    font-size: 13px;
    color: #fff;
  }
  .footer-info{
    float: left;
  }
  .footer-info p{
    text-align: left;
  }
  .credits .lien_footer, .footer-info .lien_footer {
    --font-size: 17px;
    color: #F86923;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: bold;
  }

  .credits .lien_footer:hover, .footer-info .lien_footer:hover {
    --font-size: 17px;
    color: #a74b0e;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: bold;
  }

  #footer a{
      text-decoration: none;
  }

  
  