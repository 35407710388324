.login_component{
    background-color: #fdfdfd;
    height: 100%;
    width: 100%;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.div_left{
    background-color:#0468d7  ;
    border-bottom-left-radius: 12px;
    border-top-left-radius: 12px;
}
.div_right{
    background-color:white ;
    padding: 7%;
    border-bottom-right-radius: 12px;
    border-top-right-radius: 12px;
    
}
.conditions{
    font-size: 13px;
}

.icon_person{
    background-color: rgb(250, 249, 249);
    top:-20;
    font-size: 80px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 8px;
    padding-bottom: 10px;
    color: #0468d7 ;
    
}
.connexion_texte{
    margin-top: 4%;
    margin-bottom: 10%;
    color: #fff;
}
.icon_input{
    background-color:rgb(114, 65, 65) ;
    border-radius: 6px;
}
.conditions{
    align-items: left;
    position: relative;
    left:-10;
}
.checkbox{
    margin-right: 13px;
}
.bouton,.boutons{
    color: white;
    font-size: 19px;
    font-family: 'Times New Roman', Times, serif;
    font-weight: bold;
}
.bouton{
    background-color: #0468d7 ;
    border: none;
    border-radius: 4px;
    margin: auto;
    margin-bottom: 10px;
    padding: 6px;
}
.boutons{
    margin-top: 30px;
}
.inscrire{
    float: left;
    margin: 12px;
    font-size: 15px;
   
}
.mdp_forgot{
    --float: right;
    color: rgb(228, 90, 90);
    font-size: 14px;   
}
.btn_inscrire{
    color: rgb(67, 158, 67);
    font-size: 18px;
   
}
.parametres_con{
    font-style: normal;
    font-size: 15px;
    text-align: left;
    --float: left;
}
.imageuh{
    margin-top: 70%;
}

@media (max-width:991px) {
   .div_left{
       display: none;
   } 
}
.div_left{
    align-items: center;
    float: center;
}

.icone_user_login{
    margin-top: 40%;
}
/**========================Partie pour la page Inscription=======================*/
.label_et_input{
    text-align: left;
    font-size: 18px;
}
.acces,.personne_physique,.type_user,.personne_morale,.validation_licence{
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif
}
.note{
 color: rgb(175, 8, 8);
    }
.titre{
    background-color: #0468d7 ;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: #faf7f7;
}
.grand_div_signUp{
    background-color: rgb(228, 235, 235);
}
.sexe{
    margin-left: 3px;
}
.btn_div{
    float: right;
    margin-right: 10%;
}

.invisible{
    display: none;
    
}
.btn_inscriree{
    background-color: #F86923;
    font-weight: bold;
    }
.tous_les_champs{
    background-color: rgb(247, 186, 186);
    color: rgb(20, 1, 1);
    font-family: 'Times New Roman', Times, serif;
    font-size: 20px;
}