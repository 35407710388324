
.div_envoi{
    margin-bottom: 29px;
    margin-top: 20px;
    font-family: 'Times New Roman', Times, serif;   
}
.form_et_image input,textarea{
    background-color: white;
}
.btn_envoi:hover{
    color: white;
    background: #fa986b;
    width: 170px;
    color: white;
}
.form_et_image{
    background-color: rgb(252, 253, 251);
    padding: auto;
    margin-bottom: 62px;
    padding-top: 18px;
}
.image{
    --height: 340px;
}

@media(min-width:992px){
    .mail{
        margin-left: 8%;
    }
}


@media(max-width:991px){
    .image{
        display: none;
    }
}
.btn_envoi{
    color: white;
    --font-weight: bold;
    --border: 2px solid #3f53e7 ;
    background-color: #F86923;
    margin: auto;
    border-radius: 8px;
    margin: auto;
    margin-bottom: 209px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 50px;
    padding-right: 50px;
    font-size: 20px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.succes{
    font-family: 'Times New Roman', Times, serif;
    background-color: rgb(161, 250, 195);
    padding: 20px;
    border-radius: 4px;
    margin-top: -12px;
}
.bi_succes{
    color: rgb(11, 95, 11);
    font-size:50px;
}
.message_succes{
    font-size: 19px;
    color: #04082b;
}
.contact_nous{
    border: none;
    
}

hr.botm-line {
    height: 3px;
    width: 70px;
    background:  #F86923 ;
    position: relative;
    border: 0;
    margin: 20px 0 20px 0;
}
.interrogation{
    text-align: left;
    margin-bottom: 5%;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: gray;
}
.elmt_contact{
font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
font-size: 21px;
margin: 30px;
color: rgb(176, 173, 173);
}
.icone_contact{
color: #F86923;
margin-right: 10px;
font-size: 24px;
}
.label_contact{
    text-align: left;
}
.texte_champs_vide{
    color: rgb(193, 12, 12);
  font-size: smaller;
 
}
